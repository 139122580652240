<template>
    <div>
        <div :class="['drop_container', isDragActive ? 'drag_active' : '']" @dragover="handleDragOver"
            @dragenter="handleDragEnter" @dragleave="handleDragLeave" @drop="handleDrop" @click="openFilePicker">
            <div v-if="!!files.length">
                <span v-for="(file, i) of files" :key="i" class="file-name" @click.stop>{{
                    file.name }} <v-btn class="" color="white" @click.stop="removeFile(i)" icon>✕</v-btn>
                </span>
            </div>
            <div v-else color=" orange" class="load-btn">
                Загрузить файлы
            </div>

        </div>
        <input dense type="file" :multiple="multiple" :id="currentId" required :accept="accept"
            @change="handleFileChange" />
    </div>
</template>


<script>
export default {
    name: 'FileInput',
    props: {
        value: {
            type: Array,
            require: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        /**
         * ".pdf, .xls, .word, ..."
         */
        accept: {
            type: String,
            default: "",
        },
        /**
         * Если на 1 странице рендериться несколько компонентов, 
         * нужно раздать разные ref
         */
        id: {
            type: String,
            default: "current",
        }
    },
    model: {
        prop: "value",
        event: "change-value",
    },
    data() {
        return {
            isDragActive: false,
            files: [],
        };
    },
    methods: {
        handleDragOver(event) {
            event.preventDefault();
        },
        handleDragEnter() {
            this.isDragActive = true;
        },
        handleDragLeave() {
            this.isDragActive = false;
        },
        handleFileChange(event) {
            this.updateValue(event.target.files)
        },
        handleDrop(event) {
            event.preventDefault();
            this.isDragActive = false;
            this.updateValue(event.dataTransfer.files)
        },
        removeFile(index) {
            this.files.splice(index, 1)
        },
        openFilePicker() {
            const fileInput = document.getElementById(this.currentId);
            fileInput.click();
        },
        addFile(file) {
            var fileType = file.name.split(".").pop();
            if (!!this.acceptArray && !this.acceptArray.includes(fileType)) return;
            if (this.multiple)
                this.files.push(file);
            else this.files = [file];
        },
        updateValue(fileList) {
            if (this.multiple)
                for (let i = 0; i < fileList.length; i++)
                    this.addFile(fileList.item(i));
            else
                this.addFile(fileList.item(0));
        },
    },
    watch: {
        files(val) {
            this.$emit("change-value", val);
        }
    },
    computed: {
        acceptArray() {
            if (!this.accept) return null;
            return this.accept.replace(".", "").split(",");
        },
        currentId() {
            return this.id + "_id";
        }
    }
}
</script>


<style scoped>
.file-name {
    border: solid 1px orange;
    border-radius: 12px;
    margin: 5px 5px;
    padding: 5px 10px;
    display: inline-block;
}

.load-btn {
    border: solid 1px orange;
    border-radius: 8px;
    padding: 15px 20px;
}

.delete-btn {
    border: solid 1px orange;
    border-radius: 8px;
}

.drop_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 150px;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    border: 2px dashed orange;
    color: orange;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
    position: relative;
    padding: 10px;
}

.active:hover {
    background: #41372e;
    border-color: orange;
}

.drop_container:hover .drop_title {
    color: #ffffff;
}

.drop_title {
    color: orange;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
}

.drop_container.drag_active {
    background: #796540;
}

input[type=file] {
    opacity: 0;
}

input[type=file]::file-selector-button {
    display: none;
    background: orange;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    opacity: 0;
}

input[type=file]::content {
    opacity: 0;
}

input[type=file]::file-selector-button:hover {
    background: orange;
}

.image-view {
    height: 10rem;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-view img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;
}

.image-notview {
    height: 0;
    width: 0;
}

.btn-todetermine:hover {
    background: orange;
    transform: translateY(0) scale(1.005);
}
</style>