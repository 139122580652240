<template>
  <v-card flat class="px-5 pb-5">
    <v-card-title class="pb-8">Получение маршрут квитанции</v-card-title>
    <v-row justify="center" class="pr-15 pl-4">
      <v-col cols="12" class="text-h5 px-10 mb-5">
        <v-row>
          <v-col class="color-border" v-for="(item) in providers" cols="4" align="center">{{ item }}</v-col>
        </v-row>
      </v-col>
      <v-col cols="12" align-self="center">
        <div v-if="$store.getters.user.role === $enums.Roles.Admin">
          <v-checkbox label="Пропустить проверку корректности номера билета в PDF" v-model="isSkipCheckNumberTicket"
            color="orange" />
        </div>

        <div v-if="!files.length" class="text-h5 mb-2">
          <v-icon class="mr-3 text-h4">mdi-information</v-icon>
          Загрузите оригинальную маршрут-квитанцию
        </div>
        <file-input v-model="files" accept=".pdf" id="liner" />
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col>
            <v-text-field outlined label="Номер билета без кода" v-model="numberTicket"
              style="height: 3.5rem"></v-text-field>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="6">
            <v-text-field outlined label="Cбор" v-model="markup"
              :rules="[(val) => val > 0 || 'Введите число, например 10.50']" />
          </v-col>
          <v-col cols="6">
            <v-select v-model="subClass" outlined color="orange" label="Класс" :items="classTypes" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-btn @click="downloadReceipt" :loading="isDownload" color="orange" :disabled="isDisable"
          style="width: 100%; height: 3.3rem">
          Получить
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
    </v-row>
  </v-card>
</template>

<script>
import { downloadFile } from '@/mixins/utils.js';
import FileInput from "@/components/UI/FileInput.vue";
export default {
  components: { FileInput },
  data() {
    return {
      numberTicket: "",
      docNum: "",
      markup: "",
      files: [],
      isDownload: false,
      isSkipCheckNumberTicket: false,
      subClass: 0,
      classTypes: [
        { text: "Эконом", value: 0 },
        { text: "Бизнес", value: 1 },
      ],
      providers: [
        'Сирена',
        'S7',
        'Мой агент',
        'B2BLiner',
        //'TTBooking',
        //'Portbilet',
      ]
    }
  },
  methods: {
    async downloadReceipt() {
      this.isDownload = true;
      var newNumberTicket = this.numberTicket.replace(' ', '');
      if (this.files.length > 0) {
        const reader = new FileReader();
        reader.readAsDataURL(this.files[0]);
        reader.onload = async () => {
          const base64 = reader.result.split(',')[1]; // Remove the data url part
          try {
            let resp = await this.$axios.post(this.$enums.Endpoints.Other.ExternalReceipt, {
              number: newNumberTicket,
              markup: this.markup,
              subClass: this.subClass,
              isSkipCheckNumberTicket: this.isSkipCheckNumberTicket,
              pdfBase64: base64,
            });
            let uint8Array = new Uint8Array(atob(resp.data).split('').map(function (c) { return c.charCodeAt(0); }));
            downloadFile(uint8Array, { name: `${newNumberTicket}_receipt.pdf` });
          } catch (e) {
            console.error(e);
          } finally {
            this.isDownload = false;
          }
        };
        reader.onerror = (error) => {
          console.error('Error converting file to Base64:', error);
          this.isDownload = false;
        };
      }
    }
  },
  computed: {
    isDisable() {
      return (this.numberTicket.length < 4 || this.markup.length === 0 || !this.files.length);
    },
  },

}
</script>

<style scoped>
.color-border {
  border: 1px solid orange;
}
</style>